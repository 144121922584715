var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "PlateformOrganizeForm" },
    [
      _c(
        "form-panel",
        _vm._b(
          {
            ref: "form",
            attrs: { form: _vm.form },
            on: { update: _vm.update },
            scopedSlots: _vm._u([
              {
                key: "headerSlot",
                fn: function () {
                  return [_c("br")]
                },
                proxy: true,
              },
              {
                key: "saveBeforeSlot",
                fn: function () {
                  return [
                    _c("v-button", {
                      directives: [
                        {
                          name: "loading",
                          rawName: "v-loading",
                          value: _vm.loading,
                          expression: "loading",
                        },
                      ],
                      attrs: { type: "success", text: "保存" },
                      on: { click: _vm.submitBefore },
                    }),
                  ]
                },
                proxy: true,
              },
            ]),
          },
          "form-panel",
          _vm.config,
          false
        ),
        [
          _c(
            "div",
            { staticClass: "scroll-box" },
            [
              _c(
                "col2-block",
                { attrs: { title: "账号管理" } },
                [
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "姓名",
                        prop: "userName",
                        rules: [
                          {
                            required: true,
                            message: "请输入姓名",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: { placeholder: "请输入姓名", width: _vm.width },
                        model: {
                          value: _vm.form.userName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "userName", $$v)
                          },
                          expression: "form.userName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "手机号",
                        prop: "phone",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validatePhone,
                            trigger: "blur",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入手机号",
                          width: _vm.width,
                        },
                        on: { change: _vm.phoneChange },
                        model: {
                          value: _vm.form.phone,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "phone", $$v)
                          },
                          expression: "form.phone",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "所属组织",
                        prop: "orgId",
                        rules: [
                          {
                            required: true,
                            message: "请选择所属组织",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("select-tree", {
                        directives: [
                          {
                            name: "loading",
                            rawName: "v-loading",
                            value: _vm.orgLoading,
                            expression: "orgLoading",
                          },
                        ],
                        staticClass: "org-select-tree",
                        attrs: {
                          value: _vm.form.orgId,
                          data: _vm.treeSelectData,
                          placeholder: "查询组织节点",
                          treeData: _vm.treeData,
                          treeProps: _vm.treeProps,
                          highlightCurrent: true,
                          filterNodeMethod: _vm.filterNodeMethod,
                          "expand-on-click-node": false,
                          width: _vm.width,
                        },
                        on: {
                          "update:value": function ($event) {
                            return _vm.$set(_vm.form, "orgId", $event)
                          },
                          "update:data": function ($event) {
                            _vm.treeSelectData = $event
                          },
                          nodeClick: function ($event) {
                            return _vm.nodeClick(true)
                          },
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "角色类型",
                        prop: "roleType",
                        rules: [
                          {
                            required: true,
                            message: "请选择角色类型",
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c("v-select", {
                        attrs: {
                          options: _vm.roleTypeFilterOps,
                          width: _vm.width,
                        },
                        on: { change: _vm.roleTypeChange },
                        model: {
                          value: _vm.form.roleType,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "roleType", $$v)
                          },
                          expression: "form.roleType",
                        },
                      }),
                    ],
                    1
                  ),
                  _vm.form.roleType != "107" &&
                  _vm.form.roleType &&
                  _vm.form.orgId
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "管理范围",
                              prop: "organizeId",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择管理范围",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  staticStyle: { display: "inline-block" },
                                  attrs: {
                                    placeholder: "查询管理范围",
                                    subjoin: _vm.roleExtraParams,
                                    disabled: !!_vm.defaultOrganizeId,
                                    width: _vm.width,
                                  },
                                  on: { onChange: _vm.organizeChange },
                                  model: {
                                    value: _vm.form.organizeId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "organizeId", $$v)
                                    },
                                    expression: "form.organizeId",
                                  },
                                },
                                "v-select2",
                                _vm.organizeParamsComputed,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "角色权限",
                              prop: "roleId",
                              rules: [
                                {
                                  required: true,
                                  message: "请选择角色权限",
                                  trigger: "change",
                                },
                              ],
                            },
                          },
                          [
                            _c(
                              "v-select2",
                              _vm._b(
                                {
                                  attrs: {
                                    placeholder: "查询角色权限",
                                    subjoin: _vm.rolesExtraParams,
                                    width: _vm.width,
                                  },
                                  model: {
                                    value: _vm.form.roleId,
                                    callback: function ($$v) {
                                      _vm.$set(_vm.form, "roleId", $$v)
                                    },
                                    expression: "form.roleId",
                                  },
                                },
                                "v-select2",
                                _vm.roleParams,
                                false
                              )
                            ),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "用户名",
                        prop: "loginName",
                        rules: [
                          {
                            required: !_vm.loginNameDisabled,
                            message: "请输入用户名",
                            trigger: ["blur", "change"],
                          },
                        ],
                      },
                    },
                    [
                      _c("v-input", {
                        attrs: {
                          placeholder: "请输入用户名",
                          disabled: !!_vm.loginNameDisabled,
                          width: _vm.width,
                        },
                        model: {
                          value: _vm.form.loginName,
                          callback: function ($$v) {
                            _vm.$set(_vm.form, "loginName", $$v)
                          },
                          expression: "form.loginName",
                        },
                      }),
                      _c("span", { staticClass: "right-span" }),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 0,
                                expression: "form.accountStatus === 0",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "重置密码",
                              type: "text",
                              permission: "resetPwd",
                            },
                            on: { click: _vm.resetPwd },
                          })
                        : _vm._e(),
                      _c("span", { staticClass: "right-span" }),
                      !!_vm.id && _vm.form.loginName !== "ad_01"
                        ? _c("v-button", {
                            directives: [
                              {
                                name: "show",
                                rawName: "v-show",
                                value: _vm.form.accountStatus === 0,
                                expression: "form.accountStatus === 0",
                              },
                            ],
                            staticClass: "mar-l",
                            attrs: {
                              text: "解锁",
                              type: "text",
                              permission: "unlocked",
                            },
                            on: { click: _vm.unlockID },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                  _c("v-input", {
                    staticStyle: { position: "fixed", bottom: "-9999px" },
                  }),
                  _c("v-input", {
                    staticStyle: { position: "fixed", bottom: "-9999px" },
                    attrs: { type: "password" },
                  }),
                  !_vm.id
                    ? [
                        _c(
                          "el-form-item",
                          {
                            attrs: {
                              label: "用户密码",
                              prop: "pwd",
                              rules: [
                                {
                                  required: true,
                                  validator: _vm.validatePass1,
                                  trigger: "blur",
                                },
                              ],
                            },
                          },
                          [
                            _c("v-input", {
                              ref: "input1",
                              attrs: {
                                type: "password",
                                placeholder: "请输入用户密码",
                                width: _vm.width,
                                autocomplete: "new-password",
                              },
                              model: {
                                value: _vm.form.pwd,
                                callback: function ($$v) {
                                  _vm.$set(_vm.form, "pwd", $$v)
                                },
                                expression: "form.pwd",
                              },
                            }),
                          ],
                          1
                        ),
                      ]
                    : _vm._e(),
                  _c(
                    "el-form-item",
                    {
                      attrs: {
                        label: "有效截止日期",
                        prop: "accountValidDate",
                        rules: [
                          {
                            required: true,
                            validator: _vm.validateDate,
                            trigger: "change",
                          },
                        ],
                      },
                    },
                    [
                      _c(
                        "div",
                        { staticStyle: { display: "flex" } },
                        [
                          _c("v-datepicker", {
                            attrs: {
                              type: "date",
                              format: "YYYY-MM-DD",
                              width: _vm.width,
                              minDate: new Date(),
                            },
                            on: { change: _vm.validDateChange },
                            model: {
                              value: _vm.form.accountValidDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.form, "accountValidDate", $$v)
                              },
                              expression: "form.accountValidDate",
                            },
                          }),
                          _c("span", { staticClass: "right-span" }),
                          _c("checkbox-plus", {
                            attrs: {
                              type: "default",
                              options: _vm.childTypeOps,
                              value: _vm.form.childType,
                            },
                            on: {
                              "update:value": function ($event) {
                                return _vm.$set(_vm.form, "childType", $event)
                              },
                              onChange: _vm.dateTypeChange,
                            },
                          }),
                        ],
                        1
                      ),
                    ]
                  ),
                  _vm.oauthShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "授权物业服务", prop: "oauth" } },
                        [
                          _c(
                            "div",
                            { staticClass: "check-panel" },
                            _vm._l(_vm.oauthList, function (item, index) {
                              return _c("v-checkbox", {
                                key: index,
                                attrs: {
                                  index: index,
                                  type: "border",
                                  label: item.name,
                                },
                                on: {
                                  change: (val) =>
                                    _vm.authSelect(val, item, index),
                                },
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              })
                            }),
                            1
                          ),
                          _c("div", { staticStyle: { color: "#b75151" } }, [
                            _vm._v(
                              "\n            注：授权服务之后，该用户如果具有园区管理app“服务工单”的权限，就可以查看上述选中服务的工单.\n          "
                            ),
                          ]),
                        ]
                      )
                    : _vm._e(),
                  _vm.oauthStoreShow
                    ? _c(
                        "el-form-item",
                        { attrs: { label: "授权门店", prop: "oauth" } },
                        [
                          _c(
                            "div",
                            { staticClass: "check-panel" },
                            _vm._l(_vm.oauthStoreList, function (item, index) {
                              return _c("v-checkbox", {
                                key: index,
                                attrs: {
                                  index: index,
                                  type: "border",
                                  label: item.name,
                                },
                                on: {
                                  change: (val) =>
                                    _vm.authStoreSelect(val, item, index),
                                },
                                model: {
                                  value: item.checked,
                                  callback: function ($$v) {
                                    _vm.$set(item, "checked", $$v)
                                  },
                                  expression: "item.checked",
                                },
                              })
                            }),
                            1
                          ),
                        ]
                      )
                    : _vm._e(),
                ],
                2
              ),
            ],
            1
          ),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }