<template>
  <div class="PlateformOrganizeForm">
    <form-panel
      ref="form"
      v-bind="config"
      :form="form"
      @update="update"
    >
        <template #headerSlot> <br/> </template>
      <div class="scroll-box">
        <col2-block title="账号管理">
          <!-- 这里多出的输入框是为了避免浏览器默认填充账号密码 -->
          <el-form-item label="姓名" prop="userName"
            :rules="[{required: true, message: '请输入姓名', trigger: ['blur', 'change']}]"
          >
            <v-input v-model="form.userName"
              placeholder="请输入姓名"
              :width="width" />
          </el-form-item>
          <el-form-item label="手机号" prop="phone"
            :rules="[{ required: true, validator: validatePhone, trigger: 'blur' }]"
          >
            <v-input v-model="form.phone"
              placeholder="请输入手机号"
              @change="phoneChange"
              :width="width" />
          </el-form-item>

          <el-form-item
            label="所属组织"
            prop="orgId"
            :rules="[{required: true, message: '请选择所属组织', trigger: ['blur', 'change']}]"
          >
            <select-tree
              v-loading="orgLoading"
              :value.sync="form.orgId"
              :data.sync="treeSelectData"
              placeholder="查询组织节点"
              :treeData="treeData"
              :treeProps="treeProps"
              :highlightCurrent="true"
              :filterNodeMethod="filterNodeMethod"
              :expand-on-click-node="false"
              :width="width"
              class="org-select-tree"
              @nodeClick="nodeClick(true)"
            />
          </el-form-item>

          <el-form-item label="角色类型" prop="roleType"
            :rules="[ { required: true, message: '请选择角色类型', trigger: 'change' } ]"
          >
            <v-select
              v-model="form.roleType"
              :options="roleTypeFilterOps"
              :width="width"
              @change="roleTypeChange"
            />
          </el-form-item>
          <template v-if="form.roleType != '107' && form.roleType && form.orgId">
            <el-form-item label="管理范围" prop="organizeId"
              :rules="[ { required: true, message: '请选择管理范围', trigger: 'change' } ]"
            >
              <v-select2
                style="display: inline-block"
                placeholder="查询管理范围"
                v-model="form.organizeId"
                :subjoin="roleExtraParams"
                v-bind="organizeParamsComputed"
                :disabled="!!defaultOrganizeId"
                :width="width"
                @onChange="organizeChange"
              />

            </el-form-item>
            <el-form-item label="角色权限" prop="roleId"
              :rules="[ { required: true, message: '请选择角色权限', trigger: 'change' } ]"
            >
              <v-select2
                placeholder="查询角色权限"
                v-model="form.roleId"
                :subjoin="rolesExtraParams"
                v-bind="roleParams"
                :width="width"
              ></v-select2>
            </el-form-item>
          </template>
          <el-form-item label="用户名" prop="loginName"
            :rules="[{required: !loginNameDisabled, message: '请输入用户名', trigger: ['blur', 'change']}]"
          >
            <v-input v-model="form.loginName"
              placeholder="请输入用户名"
              :disabled="!!loginNameDisabled"
              :width="width" />
              <span class="right-span"></span>
              <v-button text="重置密码" type="text" permission="resetPwd"
               class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
               v-show="form.accountStatus === 0" @click="resetPwd"
              />
              <span class="right-span"></span>
              <v-button text="解锁" type="text" permission="unlocked"
                class="mar-l" v-if="!!id && form.loginName !== 'ad_01'"
                v-show="form.accountStatus === 0" @click="unlockID"
              />
          </el-form-item>
          <v-input style="position:fixed;bottom: -9999px;" />
          <v-input type="password" style="position:fixed;bottom: -9999px;" />
          <template  v-if="!id">
            <el-form-item label="用户密码" prop="pwd"
              :rules="[{ required: true, validator: validatePass1, trigger: 'blur' }]"
            >
              <v-input ref="input1" v-model="form.pwd" type="password" placeholder="请输入用户密码" :width="width" autocomplete="new-password"/>
            </el-form-item>
          </template>
          <el-form-item label="有效截止日期" prop="accountValidDate"
            :rules="[ { required: true, validator: validateDate, trigger: 'change' } ]"
          >
            <div style="display:flex;">
              <v-datepicker type="date" format="YYYY-MM-DD"
                v-model="form.accountValidDate"
                :width="width" :minDate="new Date()"
                @change="validDateChange"
              />
              <span class="right-span"></span>
              <checkbox-plus type="default" :options="childTypeOps" :value.sync="form.childType" @onChange="dateTypeChange"></checkbox-plus>
            </div>
          </el-form-item>

          <el-form-item v-if="oauthShow" label="授权物业服务" prop="oauth">
            <div class="check-panel">
              <v-checkbox
                v-model="item.checked"
                v-for="(item, index) in oauthList"
                :key="index"
                :index="index"
                type="border"
                :label="item.name"
                @change="val => authSelect(val, item, index)"
              />
            </div>
            <div style="color: #b75151">
              注：授权服务之后，该用户如果具有园区管理app“服务工单”的权限，就可以查看上述选中服务的工单.
            </div>
          </el-form-item>
          <el-form-item v-if="oauthStoreShow" label="授权门店" prop="oauth">
            <div class="check-panel">
              <v-checkbox
                v-model="item.checked"
                v-for="(item, index) in oauthStoreList"
                :key="index"
                :index="index"
                type="border"
                :label="item.name"
                @change="val => authStoreSelect(val, item, index)"
              />
            </div>
          </el-form-item>
        </col2-block>
      </div>
        <template #saveBeforeSlot>
          <v-button type="success" v-loading="loading" text="保存" @click="submitBefore" />
        </template>
    </form-panel>
  </div>
</template>

<script>
import _ from 'lodash'
import {
  getRolesURL,
  getOrganizeURL,
  getRoleTypeURL,
  addManageUserURL,
  updateManageUserURL,
  getUserInfoURL,
  updateTypeURL,
  getViewOauthURL,
  getStoreListURL,
  resetPwgURL,
  unlockedURL,
  // 新接口
  updateEnterpriseUserInfoURL,
  addEnterpriseUserInfoURL,
  getEnterpriseUserInfoDetailURL,
  getEnterOrgTreeListURL,
  getListForRegionSelectURL,
  getRootRegionIdByOrgIdURL,
} from '../api'
import { vCheckbox } from 'components/control'
import {
  Col2Detail,
  Col2Block,
  Col2Item,
  SelectTree,
  CheckboxPlus,
} from 'components/bussiness'
import { mobileRegular } from 'common/regular'
import { communityParams } from 'common/select2Params'
import moment from 'moment'
export default {
  name: 'accountForm',
  components: {
    'checkbox-plus': CheckboxPlus,
    Col2Detail,
    Col2Block,
    SelectTree,
    vCheckbox,
    Col2Item,
  },
  props: {
    userData: {
      type: Object,
      default: () => {
        return {}
      }
    },
    // 组织选中数据
    currentData: {
      type: Object,
      default: function () {
        return {}
      }
    },
    treeType: {
      type: [Number, String],
    },
    defaultOrganizeId: String,
    defaultRoleId: String,
  },
  data () {
    return {
      width: 182,
      pageLoading: false, // 页面加载..
      loading: false, // 保存按钮加载..
      id: '',
      newId: '', // 获取新接口详情
      orgId: '', // 组织id
      orgParentId: '', // 组织父id
      submitContentType: 'application/json;charset=UTF-8',
      config: {
        queryUrl: getUserInfoURL
      },
      form: {
        userName:  undefined,
        phone: undefined,
        orgId: undefined,
        roleType: undefined,
        organizeId: undefined,
        roleId: undefined,
        loginName: undefined,
        pwd: undefined,
        pwd2: undefined,
        accountValidDate: undefined,
        oauth: undefined,
        accountStatus: 0, // 账号状态,默认有效
        id: '',
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        mobileNum: '', // 手机号
        childType: undefined,
        companyId: undefined,
      },
      oldForm: { // 原有页面的字段 - accountMgrForm
        id: '',
        userName: '',
        phone: '',
        loginName: undefined,
        pwd: '',
        pwd2: '',
        roleType: undefined,
        roleId: '',
        oauth: '',
        organizeId: '',
        desensitization: false,
        roleIds: undefined,
        orgIds: undefined,
        childType: undefined,
      },
      newForm: { // 新页面的字段 - 2020.12.9 更改
        id: '', // 此id与老接口获取详情id并不相同
        orgId: '', // 组织id
        mobileNum: '',
        accountStatus: '', // 账号状态
        accountValidDate: '',
        childType: undefined,
      },
      /* select2控件 */
      organizeParams: {
        searchUrl: getOrganizeURL,
        request: {
          text: 'orgName_',
          value: 'orgId_'
        }
      },
      organizeParams2: {
        searchUrl: getListForRegionSelectURL,
        request: {
          text: 'regionName',
          value: 'id'
        },
        response: {
          text: 'regionName',
          value: 'id'
        }
      },
      roleParams: {
        searchUrl: getRolesURL,
        request: {
          text: 'roleName_',
          value: 'roleId_'
        }
      },
      oauthList: [], // 授权物业服务列表
      oauthStoreList: [], // 授权门店列表
      roleTypeOps: [], // 账号类型
      /* select-tree 控件 */
      treeSelectData: {}, // 所选树节点的数据
      treeProps: {
        id: 'id', // 必须
        parentId: 'parentId',
        children: 'childOrgList', // 必须
        label: 'orgName', // 必须
        btn: 'operateArr' // 必须
      },
      treeData: [],
      orgList: [],
      /* 辅助状态 */
      oauthIds: [], // 授权物业ids
      oauthStoreIds: [], // 授权门店ids
      roleType: '', // 记录初始的角色类型
      userName: '', // 记录初始的用户名
      loginName: '', // 记录初始的登录名
      isBack: false, // 获取页面详情时,账号类型修改不清空相关数据
      childTypeOps: [
        {
          label: '永久',
          value: 1
        }
      ],
      orgLoading: true,
    }
  },
  computed: {
    /* 全局变量 */
    userInfo () {
      return this.$store.state.userInfo
    },
    submitUrl () {
      return this.id ? updateManageUserURL : addManageUserURL
    },
    submitUrl2 () {
      return this.id ? updateEnterpriseUserInfoURL : addEnterpriseUserInfoURL
    },
    submitMethod () {
      return this.id ? 'PUT' : 'POST'
    },
    /* select2 控件 */
    roleExtraParams () { // 管理范围
      let treeType = +this.treeType
      let companyId = this.form.companyId
      let userType = +this.form.roleType || null
      if ([102].includes(userType)) {
        // treeType 1 平台组织 102 为区域 2 租户组织 102为公司
        switch (treeType) {
          case 1:
          return {
            regionType: 2
          }
          case 2:
            return {
              regionType: 0,
              regionId: companyId,
              topRegionId: companyId,
          }
        }
      }
      if ([101].includes(userType)) {
        return {
          regionId: companyId
        }
      }
      return {
        roleType: userType
      }
    },
    rolesExtraParams () { // 角色
      let organizeId = ''
      if (this.userInfo.userType === '101') {
        organizeId = this.userInfo.orgId
      }
      return {
        roleType: this.form.roleType,
        organizeId: organizeId || this.form.organizeId
      }
    },
    oauthShow () { // 授权物业服务
      let arr = ['100', '101', '102']
      return arr.indexOf(this.form.roleType) > -1
    },
    oauthStoreShow () {
      let arr = ['101']
      return arr.indexOf(this.form.roleType) > -1
    },
    /* 过滤 */
    roleTypeFilterOps () {
      // 2 租户组织,账号只能选公司项目, 1 平台职能部门 只能选 集团+系统  3 商家组织 只能选商家门店
      if (Number(this.treeType) === 1) {
        return [...this.roleTypeOps.filter(v => v.value === '100' || v.value === '106'), {text: '区域', value: '102'}]
      } else if (Number(this.treeType) === 2) {
        return this.roleTypeOps.filter(v => v.value === '101' ||  v.value === '102')
      } else if ( Number(this.treeType) === 3) {
        return this.roleTypeOps.filter(v => v.value === '104' ||  v.value === '105')
      }
      return this.roleTypeOps
    },
    /* 控件的显隐以及禁用 */
    loginNameDisabled () { // 登录名禁止修改
      let arr = ['100', '102', '101', '107']
      return (arr.indexOf(this.form.roleType) < 0 || (this.id && this.id.length > 0))
    },
    organizeParamsComputed () {
      let userType = +this.form.roleType || null;
      if ([102].includes(userType)) {
        return this.organizeParams2
      }
      if ([101].includes(userType)) {
        return communityParams
      }
      return this.organizeParams
    }
  },
  watch: {
    defaultOrganizeId (newValue) {
      this.form.organizeId = newValue
    },
    defaultRoleId (newValue) {
      this.form.roleId = newValue
    },
    // 'form.orgId' (newValue) {
    //   this.nodeClick();
    // }
  },
  created () {
    const { id, parentId } = this.currentData || {};
    if (![undefined, null, ''].includes(id)) {
      this.orgId = id;
      this.form.orgId = id;
      this.orgParentId = parentId;
      this.form.parentId = parentId;
    }

    const { userId } = this.userData;
    // console.log('userData', this.userData);
    if (userId) {
      const { userId, id, orgId, parentOrgId, mobileNum } = this.userData;
      this.id = userId;
      this.form.orgId = orgId;
      this.orgId = orgId;
      this.orgParentId = parentOrgId;
      this.form.parentId = parentOrgId;
      this.newId = id;
    }else{
      // 没有用户信息展示默认值
      // let defaultDate = new Date(new Date().valueOf() + 2 * 365 * 24 * 60 * 60 * 1000).toLocaleDateString()
      // defaultDate = defaultDate.replace(/\//g, '-');
      let defaultDate = moment().add(2, 'years').format('YYYY-MM-DD')
      this.form.accountValidDate = defaultDate;
    }
    this.nodeClick(false);
  },
  mounted () {
    this.getTreeList();
    this.getRoleType();
    this.id && this.$refs.form.getData({ userId: this.id });
  },
  methods: {
    update (data) {
      // console.log('update', data);
      this.isBack = true
      /**
       * 老旧接口，无详细业务，且后端无档期查询接口逻辑，因而保留原有代码
       * 请求详情接口为最早的old接口，与第一次旧接口保存字段并非一一对应，所以衍生出此代码
       *
      */
      // 1. 字段匹配值
      this.form.id = data.id
      this.form.loginName = data.loginName
      this.form.phone = data.phone
      this.form.desensitization = !data.desensitization
      this.form.userName = data.userName
      this.form.organizeId = data.orgId
      this.form.roleId = data.roleId
      this.form.roleType = JSON.stringify(data.userType)
      // 2. 记录部分字段的初始状态
      this.userName = data.userName
      this.loginName = data.loginName
      this.roleType = JSON.stringify(data.userType)

      // 3. 组织多选框的列表根据权限显示不同数据
      if (this.form.roleType === '107') {
        this.orgList = data.belongOrgList && data.belongOrgList.length > 0
          ? data.belongOrgList.map(item => ({
            text: item.orgRoleName,
            id: item.roleId,
            obj: item
          })) : []
      }

      if (this.form.roleType === '101') {
        this.orgList = data.belongOrgList && data.belongOrgList.length > 0
          ? data.belongOrgList.map(item => ({
            text: item.orgName,
            id: String(item.orgId),
            obj: item
          })) : []
      }

      // 4. 请求新接口详情
      this.upload2Request()
    },
    upload2 (data) {
      if (!data) { return }
      Object.keys({ ...this.newForm }).forEach(key => {
        if (key === 'id') { // form字段id为旧id,不能覆盖
          return
        }
        this.form[key] = data[key] || this.getEmptyValue(data[key], this.form[key])
      })
      this.$set(this.newForm, 'id', data.id)
    },
    /* 表单请求之前and请求成功 */
    async submitBefore () { // 1. 调保存接口前
      // 0. 先验证表单 注释内容存在问题
      // let result = this.$refs.form.validate()
      this.$refs.form.$refs.form.validate((result) => {
        if (!result) {
          return
        }
        // 1. 深度克隆表单数据
        const oldParams = _.cloneDeep(this.form)
        this.setOldParams(oldParams)
      })
    },
    setOldParams (params) { // 2. 设置老接口参数
      // 1. 去除掉值为undefined的字段
      let oldForm = {}
      let keys = Object.keys(this.oldForm)
      keys.forEach(k => {
        if (params[k] !== undefined) {
          oldForm[k] = params[k]
        }
      })
      // 2. 字段类型修改
      oldForm.desensitization = oldForm.desensitization ? 0 : 1
      // 3. 字段名称修改
      oldForm.mobileNum = oldForm.phone
      // oldForm.adminDuty = oldForm.duty
      oldForm.password = oldForm.pwd
      oldForm.userType = oldForm.roleType
      oldForm.orgId = oldForm.organizeId  // TODO 没看懂
      // 4. 字段值填充
      this.orgList.length > 0 && (oldForm.orgIds = this.orgList.map((item) => item.id))
      this.oauthIds.length > 0 && (oldForm.subIds = this.oauthIds)
      this.oauthStoreIds.length > 0 && (oldForm.storeIds = this.oauthStoreIds)
      // 4. 区分新增和编辑，编辑需要保留原有字段, 新增则不需要
      if (!this.id) {
        delete oldForm.phone
        // delete oldForm.duty
        delete oldForm.pwd
        delete oldForm.pwd2
        delete oldForm.roleType
        delete oldForm.organizeId
        delete oldForm.oauth
      } else {
        oldForm.userId = oldForm.id
      }
      // 5. 权限
      if (oldForm.userType !== '101') {
        delete oldForm.orgIds
      }
      if (this.form.roleType === '107') {
        oldForm.roleIds = this.orgList.map(item => item.id)
        this.orgList[0].obj.orgId && (oldForm.orgId = this.orgList[0].obj.orgId) // 组织账号默认
        oldForm.roleId = oldForm.roleIds[0]
      }
      // 6. 未知业务,暂不清楚为何如此写
      oldForm.logingName = oldForm.loginName
      // 添加操作对象参数 规则：账号名称-登录名
      let dataObject = `${oldForm.userName}`
      if (oldForm.loginName) {
        dataObject += `-${oldForm.loginName}`
      }
      oldForm.dataObject = dataObject
      // 7. 调请求接口,注意老接口请求成功后再请求新接口
      this.requestOldSubmit(oldForm)
    },
    async submitSuccess (data, msg) { // 3. 老接口保存成功后
      // console.log('0. 开始调新接口')
      let onoff = await this.setNewParams()
      if (onoff) {
        // console.log('5. 新表接口正常')
      }
      if (this.id) {
        this.openAllConfig(this.id)
      } else {
        this.openAllConfig(data)
        // 当角色权限不为区域、集团、项目时，data可能是账号名的提示
        data && !(/^(\d|\w|-)+$/.test(data)) && (await this.$alert(data))
      }
      return true
    },
    async openAllConfig (id) { // 4-1. 物管端APP待办项设置————区域、项目、集团
      // 100-区域、101-项目、102-集团
      let arr = ['100', '101', '102']
      if (this.id) {
        if (arr.includes(this.form.roleType) && !arr.includes(this.roleType)) {
          // 编辑 - 如果修改后的账号类型符合要求,且原先的账户类型不符合要求,则调接口
          this.requestToAllConfig(id)
        }
      } else if (!this.id && arr.includes(this.form.roleType)) {
        // 新增 - 选择的账号类型符合要求，则调接口
        this.requestToAllConfig(id)
      }
    },
    async setNewParams () { // 4-2. 新接口
      const params = _.cloneDeep(this.form)
      let newForm = {}
      Object.keys({ ...this.newForm }).forEach(k => {
        if (k === 'id') { // 此处id不能用form,而必须是newForm的id
          newForm[k] = this.newForm[k]
          return
        }
        newForm[k] = params[k]
        if (k === 'mobileNum') {
          newForm[k] = params['phone']
        }
      })
      const result = await this.requestNewSubmit(newForm)
      return result
    },
    /* 接口类 */
    upload2Request () {
      this.pageLoading = true
      // 人员详情
      this.$axios.get(`${getEnterpriseUserInfoDetailURL}?id=${this.newId}`)
        .then((res) => {
          if (res.status === '100' || res.status === 100) {
            const { data } = res
            // console.log('userDetail', data)
            this.upload2(data)
          }
        }).finally(() => {
          this.pageLoading = false
        })
    },
    async requestOldSubmit (params) {
      // console.log(params)
      this.loading = true
      let config = {
        method: this.submitMethod,
        url: this.submitUrl,
        headers: {
          'Content-Type': this.submitContentType
        },
        data: params,
        transformRequest: [function (data) { return JSON.stringify(data) }]
      }
      this.$axios(config).then(async res => {
        if (res.status === 100 || res.status === '100') {
          // let data = res.data
          // let msg = res.msg
          // console.log('1. 老接口请求成功')
          let { data, msg } = res
          let result = await this.submitSuccess(data, msg)
          // console.log(this.orgId, this.orgParentId, '6.orgParentId 和 this.orgId')
          if (!result) {
            return
          }
          this.$message.success('保存成功')
          // this.goBack()
          this.$emit('saveSuccess')
        } else {
          this.loading = false
          // this.submitError && this.submitError(res)
        }
      })
    },
    async requestNewSubmit (params) {
      // console.log('3. 新表接口开始调')
      let result = false
      let res = await this.$axios.post(this.submitUrl2, params)
      // console.log('4. 新表接口调成功')
      this.loading = false
      if (Number(res.status) === 100 && res.msg === 'ok') {
        // console.log(JSON.stringify(this.treeSelectData), '5. 修改orgId和orgParentId')
        this.orgId = this.treeSelectData[this.treeProps.id]
        this.orgParentId = this.treeSelectData[this.treeProps.parentId]
        result = true
      }
      return result
    },
    getRoleType () { // 账号类型 100/101/102...

      const data = [{"name":"公司","id":"102"},{"name":"项目","id":"101"},{"name":"组织","id":"107"}]
      this.roleTypeOps = data.map((item) => {
        return {
          text: item.name,
          value: item.id
        }
      })
    },
    getTreeList () { // 所属部门树数据
      this.orgLoading = true
      this.$axios.get(`${getEnterOrgTreeListURL}?orgType=${this.treeType}`)
        .then((res) => {
          this.orgLoading = false
          if (res.status === 100 && res.msg === 'ok') {
            this.treeData = res.data
            this.keyTree += 1
          }
        }).catch(() => {
          this.orgLoading = false
        })
    },
    requestToAllConfig (id) { // 2.1 开启邑生活物管端APP待办项设置的请求接口
      this.$axios.post(`${updateTypeURL}?userId=${id}`).then((res) => {
        if (res.status === 100) {
          // console.log('成功了')
        }
      })
    },
    getViewOauthList (id) { // 其余控件方法 - 管理范围 - 授权物业
      let params = {
        organizeId: id,
        userType: this.form.roleType
      }
      if (this.id) {
        params.userId = this.id
      }
      this.$axios.post(getViewOauthURL, params).then((res) => {
        if (res.status === '100') {
          let oauthList = []
          let ids = []
          res.data && res.data.forEach((item) => {
            if (item.selected || item.name === '集团报事') {
              ids.push(item.id)
            }
            oauthList.push({
              id: item.id,
              name: item.name,
              checked: item.name === '集团报事' ? true : Number(item.selected) === 1
            })
          })
          this.oauthList = oauthList
          this.oauthIds = ids
        }
      })
    },
    getViewOauthStoreList (id) { // 其余控件方法-管理范围-授权门店
      let data = {
        communityId: id,
        userType: this.form.roleType
      }
      this.id && (data.userId = this.id)
      this.$axios.get(getStoreListURL, { params: data }).then((res) => {
        if (res.status === 100) {
          let oauthList = []
          let ids = []
          res.data && res.data.forEach((item) => {
            if (item.selected) {
              ids.push(item.id)
            }
            oauthList.push({
              id: item.id,
              name: item.name,
              checked: Number(item.selected) === 1
            })
          })
          this.oauthStoreList = oauthList
          this.oauthStoreIds = ids
        }
      })
    },
    resetPwd () { // 重置密码
      this.$axios.put(
        resetPwgURL,
        this.$qs.stringify({ userId: this.id })
      ).then((res) => {
        if (res.status === 100) {
          this.$alert(res.data)
        }
      })
    },
    unlockID () { // 解锁
      this.$axios.post(
        unlockedURL,
        {
          userId: this.id,
          userName: this.loginName
        }
      ).then((res) => {
        if (Number(res.status) === 100) {
          this.$alert('账号解锁成功！')
        }
      })
    },
    roleTypeChange () { // 账号类型
      if (this.isBack) {
        this.isBack = false
        return false
      }
      this.orgList = []
      this.oauthList = []
      this.oauthIds = []
      this.oauthStoreList = []
      this.oauthStoreIds = []
      this.$refs.form.clearValidate()
    },
    organizeChange (val) { // 管理范围
      if (this.oauthShow) { // 如果是授权物业
        if (val && val.id) {
          this.getViewOauthList(val.id)
        } else {
          this.oauthList = []
          this.oauthIds = []
        }
      }
      if (this.oauthStoreShow) { // 如果是授权门店
        if (val && val.id) {
          this.getViewOauthStoreList(val.id)
        } else {
          this.oauthStoreList = []
          this.oauthStoreIds = []
        }
      }
    },
    authSelect (val, item, index) {
      if (val) {
        this.oauthIds.push(item.id)
      } else {
        let index = this.oauthIds.indexOf(item.id)
        this.oauthIds.splice(index, 1)
      }
    },
    authStoreSelect (val, item, index) { // 授权门店
      if (val) {
        this.oauthStoreIds.push(item.id)
      } else {
        let index = this.oauthStoreIds.indexOf(item.id)
        this.oauthStoreIds.splice(index, 1)
      }
    },

    /* 选择树控件 */
    filterNodeMethod (value, data) { // 过滤树节点的方法
      // value 为 this.filterText 的值, data 为当前节点的数据对象-接口获取
      if (!value) return true
      return data[this.treeProps.label].indexOf(value) !== -1
    },

    validatePhone (rule, value, callback) { // 手机号验证
      if (value === '') {
        callback(new Error('请填写手机号'))
      } else if (!mobileRegular.test(value)) {
        callback(new Error('手机号不正确'))
      } else {
        callback()
      }
    },
    validatePass1 (rule, value, callback) { // 验证登录密码
    // console.log(value);
      if (['', undefined, null].includes(value)) {
        callback(new Error('请输入用户密码'))
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error('密码长度8到12个字符!'))
      } else {
        callback()
      }
    },
    validateDate (rule, value, callback) {
      if (this.form.childType) {
        callback()
        return
      }
      if (value === '') {
        callback(new Error('请选择账号有效截止日期'))
      } else {
        callback()
      }
    },
    validatePass (rule, value, callback) { // 验证再次输入的密码
      if (value === '') {
        callback(new Error('请确认用户密码'))
      } else if (value.length < 8 || value.length > 12) {
        callback(new Error('密码长度8到12个字符!'))
      } else if (value !== this.form.pwd) {
        callback(new Error('两次输入密码不一致!'))
      } else {
        callback()
      }
    },

    getEmptyValue (val, emptyVal) { // 对空值进行判断
      if (val === undefined || val === '' || val === null) {
        return emptyVal
      } else if (val === 0 || val === '0') {
        return 0
      } else if (val === false) {
        return false
      }
    },

    phoneChange (value) {
      if (!this.id) {
        if (value && mobileRegular.test(value)) {
          this.form.pwd = value.substring(3)
        }else{
          this.form.pwd = '';
        }
      }
    },

    validDateChange (val) {
      this.form.childType = undefined;
    },

    dateTypeChange (index, value, item) {
      if (value) {
        this.form.accountValidDate = '2099-01-01';
      }
    },

    async nodeClick (isReset) {
      // 获取选中树节点id  换取最上级id
      const { orgId } = this.form
      if (isReset) {
        this.form.companyId = ''
        this.form.organizeId = ''
        this.form.roleId = ''
      }
      let res = await this.$axios.get(getRootRegionIdByOrgIdURL, {params: {orgId: orgId}})
      const { data, status } = res
      if (status == 100) {
        this.form.companyId = data
      }
    }
  }
}
</script>

<style lang="scss" scoped>
  .check-panel {
    display: flex;
    flex-flow: wrap;
    padding: 10px;
    .checkbox-wrapper {
      margin-right: 5px;
      margin-bottom: 5px;
    }
  }
  .right-span{
    margin-right: 10px;
  }
  .scroll-box{
    height: 54vh;
    overflow-y: auto;
  }
  .org-select-tree{
    width: 186px;
  }
</style>
